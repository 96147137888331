import { stringToTeamMember } from 'lib/switch-fns/string-to-team-member'
import Image from 'next/image'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

type Props = {
  title: string
  intro: string
  detail: string
  url: string
  phone?: number
  email?: string
  image: string
}

const TeamMemberCard = ({ title, intro, detail, url, phone, email, image = '' }: Props) => {
  const [showBio, setShowBio] = useState(false)

  return (
    <div className="group bg-transparent perspective-1000">
      <div
        className={`relative mt-4 h-80 duration-700 preserve-3d ${showBio ? 'rotate-y-180' : ''} ${
          detail && 'sm:group-hover:rotate-y-180'
        }`}
      >
        <div className="absolute w-full grow backface-visibility-hidden">
          <div className="relative flex h-64 items-end overflow-hidden bg-tella-blue-100">
            <Image
              width={400}
              height={264}
              src={image.length > 0 ? image : stringToTeamMember(title)}
              alt="Team member"
              objectFit="contain"
              unoptimized
            />
            {detail && (
              <button
                onClick={() => setShowBio(true)}
                className="absolute bottom-4 right-4 rounded-full  bg-tella-light-pink-200 px-3 py-1.5 text-xs font-semibold leading-tight text-tella-blue-300 shadow sm:hidden"
              >
                My Bio
              </button>
            )}
            {email && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 24 24"
                className="absolute right-4 top-4 w-8 cursor-pointer fill-tella-blue-300 hover:fill-[#0a66c2]
                sm:hidden"
                onClick={() => window.open(`mailto:${email}`, '_blank')}
              >
                <path d="M3 3h18a2 2 0 012 2v14a2 2 0 01-2 2H3a2 2 0 01-2-2V5a2 2 0 012-2zm0 2v.511l9 5.368 9-5.368V5H3zm18 2.74l-9 5.368-9-5.368V19h18V7.74z" />
              </svg>
            )}
            {phone && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 24 24"
                className={`absolute right-4 ${
                  email ? 'top-14' : 'top-4'
                } w-8 cursor-pointer fill-tella-blue-300 hover:fill-[#0a66c2] sm:hidden`}
                onClick={() => window.open(`tel:${phone}`, '_blank')}
              >
                <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1.003 1.003 0 011.11-.21 11.72 11.72 0 003.6 1.12c.6.1 1.01.67 1.01 1.28v3.17a1.003 1.003 0 01-1.09 1c-9.04-.93-16.3-8.19-17.23-17.23a1.003 1.003 0 011-.99h3.17c.61 0 1.17.41 1.28 1.01.13 1.21.49 2.39 1.12 3.6.18.39.1.87-.21 1.11l-2.2 2.2z" />
              </svg>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 223.57 223.567"
              className={`absolute right-4 ${
                email && phone ? 'top-24' : email || phone ? 'top-14' : 'top-4'
              } w-8 cursor-pointer fill-tella-blue-300 hover:fill-[#0a66c2] sm:hidden`}
              onClick={() => window.open(url, '_blank')}
            >
              <g id="LinkedIn_Logo" transform="translate(-797.13 -287.426)">
                <path
                  id="Path_339"
                  data-name="Path 339"
                  d="M987.626,477.92H954.5V426.042c0-12.371-.221-28.3-17.229-28.3-17.254,0-19.894,13.478-19.894,27.395v52.775H884.25V371.234h31.8v14.579h.446a34.893,34.893,0,0,1,31.377-17.232c33.575,0,39.766,22.084,39.766,50.815ZM846.872,356.651h0a19.223,19.223,0,1,1,19.224-19.227v0a19.315,19.315,0,0,1-19.22,19.223M863.435,477.92H830.274V371.234h33.161ZM1004.14,287.441H813.627a16.392,16.392,0,0,0-16.5,16.114v191.3a16.4,16.4,0,0,0,16.5,16.132H1004.14a16.429,16.429,0,0,0,16.56-16.132V303.542a16.42,16.42,0,0,0-16.56-16.115"
                />
              </g>
            </svg>
          </div>
          <div className="mx-auto mt-2 flex flex-col">
            <p className="font-semibold leading-none">{title}</p>
            <p className="text-sm opacity-80">{intro}</p>
          </div>
        </div>
        <div className={`absolute grid w-full overflow-hidden rotate-y-180 backface-visibility-hidden`}>
          <div className="relative flex h-64 items-center overflow-hidden border-8 border-tella-blue-100 bg-tella-light-pink-200 p-2 text-left shadow">
            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className="rich-text">
              {detail}
            </ReactMarkdown>
            <button
              onClick={() => setShowBio(false)}
              className="absolute bottom-4 right-4 rounded-full bg-white px-3 py-1.5 text-xs font-semibold leading-tight text-tella-blue-300 shadow sm:hidden"
            >
              My Photo
            </button>
          </div>
          <div className="relative overflow-hidden pt-2">
            <p className="font-semibold leading-none">{title}</p>
            <p className="text-sm opacity-80">{intro}</p>
            {email && (
              <div title={email}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28px"
                  height="28px"
                  viewBox="0 0 24 24"
                  className="absolute -top-10 left-4 hidden w-8 cursor-pointer fill-tella-blue-300 delay-500 duration-300 hover:fill-[#0a66c2]
      hover:transition-none group-hover:top-2.5 sm:block"
                  onClick={() => window.open(`mailto:${email}`, '_blank')}
                >
                  <path d="M3 3h18a2 2 0 012 2v14a2 2 0 01-2 2H3a2 2 0 01-2-2V5a2 2 0 012-2zm0 2v.511l9 5.368 9-5.368V5H3zm18 2.74l-9 5.368-9-5.368V19h18V7.74z" />
                </svg>
              </div>
            )}
            {phone && (
              <div title={phone.toString()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28px"
                  height="28px"
                  viewBox="0 0 24 24"
                  className={`absolute -top-10 left-${
                    email ? '14' : '4'
                  } hidden w-8 cursor-pointer fill-tella-blue-300 delay-500 duration-300 hover:fill-[#0a66c2] hover:transition-none group-hover:top-2.5 sm:block`}
                  onClick={() => window.open(`tel:${phone}`, '_blank')}
                >
                  <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1.003 1.003 0 011.11-.21 11.72 11.72 0 003.6 1.12c.6.1 1.01.67 1.01 1.28v3.17a1.003 1.003 0 01-1.09 1c-9.04-.93-16.3-8.19-17.23-17.23a1.003 1.003 0 011-.99h3.17c.61 0 1.17.41 1.28 1.01.13 1.21.49 2.39 1.12 3.6.18.39.1.87-.21 1.11l-2.2 2.2z" />
                </svg>
              </div>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 223.57 223.567"
              className="absolute -top-10 right-5 hidden w-8 cursor-pointer fill-tella-blue-300 delay-500 duration-300 hover:fill-[#0a66c2]
           hover:transition-none group-hover:top-2.5 sm:block"
              onClick={() => window.open(url, '_blank')}
            >
              <g id="LinkedIn_Logo" transform="translate(-797.13 -287.426)">
                <path
                  id="Path_339"
                  data-name="Path 339"
                  d="M987.626,477.92H954.5V426.042c0-12.371-.221-28.3-17.229-28.3-17.254,0-19.894,13.478-19.894,27.395v52.775H884.25V371.234h31.8v14.579h.446a34.893,34.893,0,0,1,31.377-17.232c33.575,0,39.766,22.084,39.766,50.815ZM846.872,356.651h0a19.223,19.223,0,1,1,19.224-19.227v0a19.315,19.315,0,0,1-19.22,19.223M863.435,477.92H830.274V371.234h33.161ZM1004.14,287.441H813.627a16.392,16.392,0,0,0-16.5,16.114v191.3a16.4,16.4,0,0,0,16.5,16.132H1004.14a16.429,16.429,0,0,0,16.56-16.132V303.542a16.42,16.42,0,0,0-16.56-16.115"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMemberCard
