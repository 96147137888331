import { ReactNode } from 'react'

type Props = {
  id?: string
  leftSideChildren?: ReactNode
  rightSideChildren?: ReactNode
  containerClass?: string
}

const ContainerFourEight = ({ id, leftSideChildren, rightSideChildren, containerClass }: Props) => {
  return (
    <div className={`flex flex-col gap-4 py-8 md:flex-row ${containerClass ? containerClass : ''}`} id={id}>
      <div className="md:w-4/12">{leftSideChildren}</div>
      <div className="md:w-8/12">{rightSideChildren}</div>
    </div>
  )
}

export default ContainerFourEight
