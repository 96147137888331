import { useRouter } from 'next/router'

type Props = {
  breadCrumbTitles: string[]
}

const BreadCrumbs = ({ breadCrumbTitles }: Props) => {
  const router = useRouter()
  const linkPath = router.asPath.split('/')

  const pathArray = [
    { breadcrumb: '/', title: 'Home', href: '/' },
    ...linkPath.map((path, i) => {
      return {
        breadcrumb: path,
        title: breadCrumbTitles[i],
        href: linkPath.slice(0, i + 1).join('/'),
      }
    }),
  ]

  const filteredPaths = pathArray.filter(
    (path, index) => path.title !== '' && path.breadcrumb !== '' && index !== pathArray.length - 1
  )

  return (
    <ol
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      className="flex h-6 w-max items-center rounded-lg bg-white bg-opacity-80 px-3 text-tella-blue-300"
    >
      {filteredPaths.map((path, index) => (
        <li key={index} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" className="flex">
          <a
            href={path.href}
            itemScope
            itemProp="item"
            itemID={path.href}
            itemType="http://schema.org/Thing"
            className="text-xs hover:underline"
          >
            <span itemProp="name">{path.title}</span>
          </a>
          <meta itemProp="position" content={index.toString()} />
          {index !== filteredPaths.length - 1 && filteredPaths.length > 1 && (
            <p className="text-xs font-semibold">&nbsp;{'>'}&nbsp;</p>
          )}
        </li>
      ))}
    </ol>
  )
}

export default BreadCrumbs
