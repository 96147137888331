import { motion } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'
import { accordionVariants } from 'styles/motion-variants'

import { ChevronDownIcon } from '@heroicons/react/solid'

type Props = {
  title: string
  children: ReactNode
  color?: string
  border?: string
  themeWhenChild?: boolean
  containerClass?: string
  openWithTitle?: string
  onClick?: () => void
}

const GroupedAccordion = ({ title, color, children, border, openWithTitle, containerClass, onClick }: Props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    if (openWithTitle === title) {
      setIsAccordionOpen(true)
    } else setIsAccordionOpen(false)
  }, [openWithTitle, title])

  return (
    <div className={`w-full ${containerClass ?? ''}`}>
      <div
        className={`flex w-full cursor-pointer items-center justify-between py-4 font-semibold ${
          color ? color : 'text-tella-blue-300'
        }`}
        onClick={onClick}
      >
        {title}
        <ChevronDownIcon className={`h-6 w-6 transition duration-500 ${isAccordionOpen ? 'rotate-180' : 'rotate-0'}`} />
      </div>
      <motion.div
        animate={isAccordionOpen ? 'open' : 'close'}
        transition={{ ease: 'easeInOut' }}
        variants={accordionVariants}
        className={`flex overflow-hidden text-tella-blue-300 ${border ? border : 'border-b border-tella-blue-300'}`}
      >
        <div className="w-full">{children}</div>
      </motion.div>
    </div>
  )
}

export default GroupedAccordion
