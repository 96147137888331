import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ReactNode } from 'react'
import Slider from 'react-slick'

type Props = {
  children: ReactNode[]
}

const settings = {
  dots: true,
  // infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: true,
}

const HeroCarousel = ({ children }: Props) => {
  return <Slider {...settings}>{children}</Slider>
}

export default HeroCarousel
