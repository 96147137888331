import TellaLogoPng from 'images/others/tella-logo.png'
import AndreaRowlands from 'images/team-members/andrea-rowlands.png'
import AndrewChambers from 'images/team-members/andrew-chambers.png'
import AvarioBabuska from 'images/team-members/avario-babuska.png'
import BenGoldsmith from 'images/team-members/ben-goldsmith.png'
import ChrisStevens from 'images/team-members/chris-stevens.png'
import ChrishanaStanley from 'images/team-members/chrishana-stanley.png'
import JeffSun from 'images/team-members/jeff-sun.png'
import JohnTrenwith from 'images/team-members/john-trenwith.png'
import JoyceRam from 'images/team-members/joyce-ram.png'
import KavinMurthy from 'images/team-members/kavin-murthy.png'
import KrishnaYadav from 'images/team-members/krishna-yadav.png'
import NadiiaSyvakivska from 'images/team-members/nadiia-syvakivska.png'
import SimranSingh from 'images/team-members/simran-singh.png'
import StephaneMathieu from 'images/team-members/stephane-mathieu.png'
import WayneChoi from 'images/team-members/wayne-choi.png'

export const stringToTeamMember = (str: string) => {
  switch (str) {
    case 'Andrea Rowlands':
      return AndreaRowlands
    case 'Andrew Chambers':
      return AndrewChambers
    case 'Ben Goldsmith':
      return BenGoldsmith
    case 'Chris Stevens':
      return ChrisStevens
    case 'Chrishana Stanley':
      return ChrishanaStanley
    case 'John Trenwith':
      return JohnTrenwith
    case 'Joyce Ram':
      return JoyceRam
    case 'Krishna Yadav':
      return KrishnaYadav
    case 'Simran Singh':
      return SimranSingh
    case 'Stephane Mathieu':
      return StephaneMathieu
    case 'Wayne Choi':
      return WayneChoi
    case 'Avario Babuska':
      return AvarioBabuska
    case 'Nadiia Syvakivska':
      return NadiiaSyvakivska
    case 'Kavin Murthy':
      return KavinMurthy
    case 'Jeff Sun':
      return JeffSun
    default:
      return TellaLogoPng
  }
}
