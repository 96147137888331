import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

type Props = {
  children: string
  containerClass?: string
}

const Markdown = ({ children, containerClass }: Props) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeRaw]}
    className={`rich-text ${containerClass ?? ''}`}
  >
    {children}
  </ReactMarkdown>
)

export default Markdown
