import TextWithImageButton from 'components/content-blocks/text-with-image-button'
import Button from 'components/elements/buttons/button'
import TeamMemberCard from 'components/elements/cards/team-member-card'
import ContainerFourEight from 'components/elements/containers/conatiner-four-eight'
import Container from 'components/elements/containers/container'
import GroupedAccordion from 'components/elements/containers/grouped-accordion'
import GuideContentAccordion from 'components/elements/containers/guide-content-accordion'
import Hero from 'components/layouts/hero-section/hero'
import HeroCarousel from 'components/layouts/hero-section/hero-carousel'
import { HeroColorAndGlossaryLetterContext } from 'components/layouts/layout'
import LadyAndSofa from 'images/home-page/lady-and-sofa.png'
import { fetchStrapiAPI } from 'lib/api'
import { EV_BOOK_A_CHAT, EV_HL_APPLICATION_PATH_CLICK } from 'lib/constants/analytics-events'
import * as href from 'lib/constants/hrefs'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import StyleToLinkComponent from 'lib/switch-fns/style-to-link-component'
import { fireGAEvent, scrollToElement } from 'lib/utilities'
import { GetStaticPaths, GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import {
  isContentBlockFaqs,
  isContentBlockKeyFacts,
  isContentBlockLinks,
  isContentBlockRichText,
  isContentBlockTeams,
  isContentBlockText,
  isContentBlockTextAndImage,
  isContentBlockTexts,
  isContentBlockTextsWithImage,
  isContentBlockTextWithImageButton,
  isContentBlockUniversals,
  isContentBlockVideo,
} from 'types/strapi/content-blocks'
import { Page } from 'types/strapi/pages'

import * as Sentry from '@sentry/react'

const Markdown = dynamic(() => import('components/markdown/markdown'))
const ReactPlayer = dynamic(() => import('react-player'))

type Props = {
  pages: Page
}

const Pages = ({ pages }: Props) => {
  const { setHeroBgColor } = useContext(HeroColorAndGlossaryLetterContext)
  const [idInView, setIdInView] = useState('')
  const [storedCampaignString] = useState(typeof window !== 'undefined' ? localStorage.queryString : '')
  const [faqAccordionQuestion, setFaqAccordionQuestion] = useState('')
  const [activeAccordionImportantInfo, setActiveAccordionImportantInfo] = useState('')

  useEffect(() => {
    setHeroBgColor(pages?.attributes.hero?.backgroundColor || '')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { contentBlocks, hero, heroes, seo, shortIntro, pageTitle, urlSlug } = pages?.attributes || {}

  return (
    <>
      {pages && (
        <>
          <Head>
            <title>{seo?.metaTitle || pageTitle}</title>
            <meta name="description" content={seo?.metaDescription || hero?.intro} />
            {seo?.keywords && <meta name="keywords" content={seo.keywords} />}
          </Head>
          <h1 className="hidden">{hero?.title || pageTitle}</h1>
          <HeroCarousel>
            {hero && (
              <Hero
                title={hero.title || pageTitle || 'Add a title'}
                intro={hero.intro || shortIntro || 'Add an intro'}
                breadCrumbTitles={[' ', 'Home Loans']}
                image={hero.image}
                imageUrl={
                  hero.banner?.data?.attributes.formats.medium?.url || hero.banner?.data?.attributes.formats.small?.url
                }
                buttonText={hero.buttonText}
                buttonLink={hero.buttonLink}
                isButtonLinkExternal={hero.isLinkExternal}
                backgroundColor={hero.backgroundColor}
                textColor={hero.textColor}
                extraLogo={hero.extraLogo?.data?.attributes.formats.small.url}
                extraLogoAlt={hero.extraLogo?.data?.attributes.alternativeText}
              />
            )}
            {heroes?.map((hero, i) => (
              <Hero
                key={i}
                title={hero.title || pageTitle || 'Add a title'}
                intro={hero.intro || shortIntro || 'Add an intro'}
                breadCrumbTitles={[' ', 'Home Loans']}
                image={hero.image}
                imageUrl={
                  hero.banner?.data?.attributes.formats.medium?.url || hero.banner?.data?.attributes.formats.small?.url
                }
                buttonText={hero.buttonText}
                buttonLink={hero.buttonLink}
                isButtonLinkExternal={hero.isLinkExternal}
                backgroundColor={hero.backgroundColor}
                textColor={hero.textColor}
              />
            ))}
          </HeroCarousel>
          {urlSlug !== 'faqs' &&
            urlSlug !== 'important-info' &&
            (contentBlocks.length > 2 ? (
              <>
                <Container
                  id="in-page-nav"
                  containerClass="sticky top-10 z-20"
                  contentContainerClass="px-3 pt-12 hidden md:block"
                  bgColor="bg-tella-light-pink-200"
                >
                  <div className="mx-auto flex w-11/12 space-x-6 border-b border-tella-blue-300">
                    {contentBlocks.map((contentBlock, index) => (
                      <div
                        key={index}
                        className="flex cursor-pointer flex-col justify-between hover:opacity-80"
                        onClick={() => {
                          'heading' in contentBlock &&
                            contentBlock.heading &&
                            scrollToElement(contentBlock.heading.replace(/\s/g, ''))
                        }}
                      >
                        <h5
                          className={`${
                            idInView === ('heading' in contentBlock && contentBlock.heading.replace(/\s/g, ''))
                              ? 'font-black'
                              : ''
                          }`}
                        >
                          {'heading' in contentBlock &&
                            contentBlock.heading !== 'Take out a home loan with Tella and get a $200 Farmers card!' &&
                            contentBlock.heading}
                        </h5>
                        <div style={{ height: '4px' }} className="mt-4 rotate-180">
                          <div
                            className={`flex w-full bg-tella-blue-300 transition-all duration-300`}
                            style={
                              idInView === ('heading' in contentBlock && contentBlock.heading.replace(/\s/g, ''))
                                ? { height: '4px' }
                                : { height: '0' }
                            }
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Container>
                <Container
                  containerClass="sticky top-10 z-20"
                  contentContainerClass="px-4 pt-8 pb-2.5 md:hidden"
                  bgColor="bg-tella-light-pink-200"
                >
                  <div className="mx-auto w-11/12">
                    <select
                      id="in-page-nav"
                      name="in-page-nav"
                      className="w-full rounded-md border-r-8 border-white py-2.5 pl-3 outline-none"
                      onChange={(e) => {
                        scrollToElement(e.target.value)
                      }}
                    >
                      {contentBlocks.map(
                        (contentBlock, i) =>
                          'heading' in contentBlock && (
                            <option key={i} value={contentBlock.heading.replace(/\s/g, '')}>
                              {contentBlock.heading}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </Container>
              </>
            ) : (
              <div className="h-16"></div>
            ))}

          {urlSlug === 'important-info' ? (
            <>
              {contentBlocks.map((contentBlock, index) => (
                <div key={index}>
                  {isContentBlockTexts(contentBlock) && (
                    <Container contentContainerClass="px-4 py-1">
                      <div className="mx-auto w-full lg:w-9/12">
                        <GuideContentAccordion
                          heading={contentBlock.heading}
                          isOpen={contentBlock.heading === activeAccordionImportantInfo ? true : false}
                          onClick={() =>
                            activeAccordionImportantInfo === contentBlock.heading
                              ? setActiveAccordionImportantInfo('')
                              : setActiveAccordionImportantInfo(contentBlock.heading)
                          }
                        >
                          {contentBlock.texts.map((text, index) => (
                            <div key={index} className="flex flex-col gap-2">
                              <h6 className="font-semibold">{text.heading}</h6>
                              <Markdown>{text.text}</Markdown>
                            </div>
                          ))}
                        </GuideContentAccordion>
                      </div>
                    </Container>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {contentBlocks.map((contentBlock, index) => (
                <div key={index}>
                  <InView
                    onChange={(entry) => {
                      entry && setIdInView(('heading' in contentBlock && contentBlock.heading.replace(/\s/g, '')) || '')
                    }}
                    threshold={0.4}
                  >
                    {isContentBlockTexts(contentBlock) && (
                      <Container id={contentBlock.heading.replace(/\s/g, '')}>
                        <ContainerFourEight
                          containerClass="w-11/12 mx-auto"
                          leftSideChildren={<h2 className="font-semibold">{contentBlock.heading}</h2>}
                          rightSideChildren={
                            <div className="md:mt-28">
                              {contentBlock.texts.map((text, index) => (
                                <ContainerFourEight
                                  key={index}
                                  containerClass="border-t-2 border-tella-blue-300"
                                  leftSideChildren={<h3 className="font-semibold">{text.heading}</h3>}
                                  rightSideChildren={text.text}
                                />
                              ))}
                            </div>
                          }
                        />
                      </Container>
                    )}

                    {isContentBlockVideo(contentBlock) && (
                      <Container id={contentBlock.heading.replace(/\s/g, '')}>
                        <ReactPlayer url={contentBlock.youtubeUrl} width="100%" />
                      </Container>
                    )}

                    {isContentBlockTextsWithImage(contentBlock) && (
                      <>
                        {contentBlock.style === 'style1' && (
                          <Container id={contentBlock.heading.replace(/\s/g, '')}>
                            <div className="mx-auto flex w-11/12 flex-col gap-8">
                              <div className="md:w-1/2">
                                <h3 className="font-semibold">{contentBlock.heading}</h3>
                                <p className="mt-2">{contentBlock.intro}</p>
                              </div>
                              <div className="flex flex-wrap gap-8">
                                {contentBlock.textsWithImage.map((textWithImage, index) => (
                                  <div key={index} className="w-full border-t-2 border-tella-blue-300 py-4 md:w-[46%]">
                                    <h2 className="h-16 w-3/5 text-5xl font-semibold">{textWithImage.title}</h2>
                                    <div className="flex items-center">
                                      <p className="w-1/2">{textWithImage.intro}</p>
                                      <div>{stringToImgComponent(textWithImage.image)}</div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Container>
                        )}
                      </>
                    )}

                    {isContentBlockText(contentBlock) && (
                      <Container id={contentBlock.heading.replace(/\s/g, '')}>
                        <div className="mx-auto flex flex-col items-end md:w-11/12 md:flex-row md:justify-between">
                          <h3 className="text-4xl font-semibold md:w-2/3">{contentBlock.text}</h3>
                          <p className="font-semibold">{contentBlock.heading}</p>
                        </div>
                      </Container>
                    )}

                    {isContentBlockRichText(contentBlock) && (
                      <Container>
                        <div className="rich-text mx-auto w-11/12">
                          <Markdown>{contentBlock.text}</Markdown>
                        </div>
                      </Container>
                    )}

                    {isContentBlockKeyFacts(contentBlock) && (
                      <Container bgColor="bg-white" id={contentBlock.heading.replace(/\s/g, '')}>
                        <ContainerFourEight
                          containerClass="w-11/12 mx-auto"
                          leftSideChildren={<h2 className="font-semibold">{contentBlock.heading}</h2>}
                          rightSideChildren={
                            <div className="md:mt-28">
                              <Markdown containerClass="pb-8">{contentBlock.details}</Markdown>
                              {contentBlock.keyFacts.map((keyFact, index) => (
                                <ContainerFourEight
                                  key={index}
                                  containerClass="border-t-2 border-tella-blue-300"
                                  leftSideChildren={<h3 className="font-semibold">{keyFact.heading}</h3>}
                                  rightSideChildren={<Markdown>{keyFact.details}</Markdown>}
                                />
                              ))}
                            </div>
                          }
                        />
                      </Container>
                    )}

                    {isContentBlockFaqs(contentBlock) && (
                      <Container id={contentBlock.heading.replace(/\s/g, '')} contentContainerClass="px-4 pt-12">
                        <div className="mx-auto sm:w-11/12">
                          <h2 className="mb-2 font-semibold">{contentBlock.heading}</h2>
                          {contentBlock.faqs.map((faq, index) => (
                            <GroupedAccordion
                              title={faq.question}
                              key={index}
                              openWithTitle={
                                faqAccordionQuestion ? faqAccordionQuestion : contentBlock.faqs[0].question
                              }
                              onClick={() =>
                                faqAccordionQuestion === faq.question
                                  ? setFaqAccordionQuestion('x')
                                  : setFaqAccordionQuestion(faq.question)
                              }
                            >
                              <Markdown>{faq.answer}</Markdown>
                            </GroupedAccordion>
                          ))}
                        </div>
                      </Container>
                    )}

                    {isContentBlockUniversals(contentBlock) && (
                      <Container bgColor="bg-white" id={contentBlock.heading.replace(/\s/g, '')}>
                        <div className="mx-auto grid w-11/12 grid-flow-row">
                          <div className="md:max-w-2xl">
                            <h3 className="text-4xl font-semibold">{contentBlock.heading}</h3>
                            <Markdown containerClass="mt-4">{contentBlock.headIntro}</Markdown>
                          </div>
                          <div className="mx-auto grid w-full text-center sm:w-full sm:grid-cols-2 lg:grid-cols-3">
                            {contentBlock.universals.map((member, index) => (
                              <TeamMemberCard
                                title={member.title}
                                intro={member.intro}
                                url={member.url}
                                detail={member.detail}
                                key={index}
                                image={member.image?.data?.attributes.url ?? ''}
                              />
                            ))}
                          </div>
                        </div>
                      </Container>
                    )}

                    {isContentBlockTeams(contentBlock) && (
                      <Container bgColor="bg-white" id={contentBlock.heading.replace(/\s/g, '')}>
                        <div className="mx-auto grid w-11/12 grid-flow-row">
                          <div className="md:max-w-2xl">
                            <h3 className="text-4xl font-semibold">{contentBlock.heading}</h3>
                            <Markdown containerClass="mt-4">{contentBlock.headIntro}</Markdown>
                          </div>
                          {contentBlock.members?.filter((x) => x.ourAdvisers).length > 0 && (
                            <>
                              <h4 className="text-3xl font-semibold">Our Advisers</h4>
                              <div className="mx-auto grid w-full text-center sm:w-full sm:grid-cols-2 lg:grid-cols-3">
                                {contentBlock.members
                                  ?.filter((x) => x.ourAdvisers)
                                  .map((member, index) => (
                                    <TeamMemberCard
                                      email={member.email}
                                      phone={member.phone}
                                      title={member.title}
                                      intro={member.intro}
                                      url={member.url}
                                      detail={member.detail}
                                      key={index}
                                      image={member.image?.data?.attributes.url ?? ''}
                                    />
                                  ))}
                              </div>
                            </>
                          )}
                          {contentBlock.members?.filter((x) => x.operationsTeam).length > 0 && (
                            <>
                              <h4 className="text-3xl font-semibold">Operations Team</h4>
                              <div className="mx-auto grid w-full text-center sm:w-full sm:grid-cols-2 lg:grid-cols-3">
                                {contentBlock.members
                                  ?.filter((x) => x.operationsTeam)
                                  .map((member, index) => (
                                    <TeamMemberCard
                                      title={member.title}
                                      intro={member.intro}
                                      url={member.url}
                                      email={member.email}
                                      phone={member.phone}
                                      detail={member.detail}
                                      key={index}
                                      image={member.image?.data?.attributes.url ?? ''}
                                    />
                                  ))}
                              </div>
                            </>
                          )}
                          {contentBlock.members?.filter((x) => x.leadershipTeam).length > 0 && (
                            <>
                              <h4 className="text-3xl font-semibold">Leadership Team</h4>
                              <div className="mx-auto grid w-full text-center sm:w-full sm:grid-cols-2 lg:grid-cols-3">
                                {contentBlock.members
                                  ?.filter((x) => x.leadershipTeam)
                                  .map((member, index) => (
                                    <TeamMemberCard
                                      title={member.title}
                                      intro={member.intro}
                                      url={member.url}
                                      email={member.email}
                                      phone={member.phone}
                                      detail={member.detail}
                                      key={index}
                                      image={member.image?.data?.attributes.url ?? ''}
                                    />
                                  ))}
                              </div>
                            </>
                          )}
                        </div>
                      </Container>
                    )}

                    {isContentBlockLinks(contentBlock) && (
                      <Container id={contentBlock.heading.replace(/\s/g, '')} contentContainerClass="px-4 pt-12">
                        <div className="mx-auto flex w-11/12 flex-col gap-6">
                          <h2 className="font-semibold">{contentBlock.heading}</h2>
                          <div className="flex flex-wrap">
                            {contentBlock?.links?.map((link, index) => (
                              <StyleToLinkComponent
                                key={index}
                                style={contentBlock.style}
                                link={link}
                                queryString={link.predefinedUrls === 'Application' ? '' : storedCampaignString}
                              />
                            ))}
                          </div>
                        </div>
                      </Container>
                    )}
                  </InView>
                  {isContentBlockTextWithImageButton(contentBlock) && (
                    <Container>
                      <TextWithImageButton
                        textWithImage={contentBlock.textWithImage}
                        button={contentBlock.button}
                        switchContentPlacement={contentBlock.switchContentPlacement}
                      />
                    </Container>
                  )}
                  {isContentBlockTextAndImage(contentBlock) && contentBlock.type === 'promotion' && (
                    <Container bgColor="bg-tella-light-pink-100">
                      <div className="mx-auto mb-10 mt-10 grid w-10/12 grid-cols-1 gap-10 md:mb-0 md:mt-12 md:grid-cols-2 md:pr-10">
                        <div>
                          <p className="text-lg text-[#37C49F]">{contentBlock.colorTitle}</p>
                          <p className="mb-5 text-3xl font-semibold lg:text-4xl">{contentBlock.heading}</p>
                          <p
                            className="mb-5"
                            dangerouslySetInnerHTML={{ __html: contentBlock.intro.replace(/\n/g, '<br/>') }}
                          />
                          <div className="mt-14 grid gap-2 md:flex">
                            <Link href={href.BOOK_A_CHAT} passHref>
                              <a className="w-full">
                                <Button
                                  style="Green"
                                  text="Book a chat"
                                  width="w-full"
                                  onClick={() => fireGAEvent({ event: EV_BOOK_A_CHAT })}
                                />
                              </a>
                            </Link>
                            <Link href={href.HL_APPLICATION} passHref>
                              <a className="w-full" target="_blank">
                                <Button
                                  style="Green"
                                  text="Apply online"
                                  width="w-full"
                                  onClick={() =>
                                    fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online' })
                                  }
                                />
                              </a>
                            </Link>
                          </div>
                        </div>
                        <div className="">
                          <div className="relative right-3 w-full sm:right-6 md:right-0 md:top-24 md:w-[350px] lg:-top-10  lg:w-[520px]">
                            <Image
                              objectFit="cover"
                              objectPosition="center"
                              src={contentBlock.sectionImage?.data.attributes.url ?? LadyAndSofa}
                              height={100}
                              width={100}
                              alt="Get Started with Tella"
                              quality={100}
                              layout="responsive"
                            />
                          </div>
                        </div>
                      </div>
                    </Container>
                  )}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  )
}

export default Pages

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await fetchStrapiAPI<Page[]>('/website-pages')
  return {
    paths: pages
      .filter(
        (x) =>
          x.attributes.urlSlug !== null &&
          x.attributes.urlSlug !== 'home-loans-mortgages' &&
          x.attributes.urlSlug.substring(0, 32) !== 'home-loans-mortgages/calculators' &&
          x.attributes.urlSlug.substring(0, 11) !== 'calculators' &&
          x.attributes.urlSlug !== 'home-loans-mortgages/rates' &&
          x.attributes.urlSlug !== 'partner-with-tella' &&
          x.attributes.urlSlug !== 'realestate-partner' &&
          x.attributes.urlSlug !== 'realestate' &&
          x.attributes.urlSlug !== 'realestate-refer' &&
          x.attributes.urlSlug !== 'professional-refer'
      )
      .map((page) => ({ params: { slug: page.attributes.urlSlug.split('/') } })),
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const urlSlug = context.params?.slug
  if (urlSlug) {
    try {
      const pages = await fetchStrapiAPI<Page[]>(
        `/website-pages?populate[0]=seo&populate[1]=contentBlocks.texts&populate[2]=contentBlocks.textsWithImage&populate[3]=contentBlocks.richtext&populate[4]=contentBlocks.keyFacts&populate[5]=contentBlocks.faqs&populate[6]=contentBlocks.links.guideCategory&populate[7]=contentBlocks.links.guide.category.parent&populate[8]=hero.banner&populate[9]=contentBlocks.links.guide.hero&populate[10]=hero&populate[11]=contentBlocks.textWithImage&populate[12]=contentBlocks.button.websitePage&populate[13]=contentBlocks.universals&populate[14]=heroes.banner&populate[15]=contentBlocks.universals.image&populate[16]=hero.extraLogo&populate[17]=contentBlocks.links.websitePage
        &populate[18]=contentBlocks.members&populate[19]=contentBlocks.members.image
        &filters[urlSlug][$eq]=${
          typeof urlSlug === 'string' ? urlSlug : urlSlug.join('/')
        }&populate[20]=contentBlocks.mortgageSubComponent'`
      )

      if (pages.length === 1) {
        return {
          props: {
            pages: pages[0],
          },
          revalidate: parseInt(process.env.NEXT_REVALIDATE || '600'),
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error)
      } else {
        Sentry.captureException(error)
      }
    }
  }
  return {
    notFound: true,
    // fallback: true,
  }
}
