import { motion } from 'framer-motion'
import { ReactNode } from 'react'
import { accordionVariants } from 'styles/motion-variants'

import { ChevronRightIcon } from '@heroicons/react/solid'

type Props = {
  heading: string
  children: ReactNode
  isOpen: boolean
  onClick: () => void
}

const GuideContentAccordion = ({ heading, children, isOpen, onClick }: Props) => {
  return (
    <div className="border-t-2 border-tella-blue-300">
      <div className="my-4 flex cursor-pointer space-x-2" onClick={onClick}>
        <h3 className="w-full font-semibold">{heading}</h3>
        <span className="flex h-10 w-10 justify-center rounded-full bg-tella-blue-300 p-2 text-white transition-all">
          <ChevronRightIcon className={`w-6 stroke-0 transition duration-500 ${isOpen ? 'rotate-90' : 'rotate-0'}`} />
        </span>
      </div>
      <motion.div
        animate={isOpen ? 'open' : 'close'}
        transition={{ ease: 'easeInOut' }}
        variants={accordionVariants}
        className="h-0 overflow-hidden"
      >
        {children}
      </motion.div>
    </div>
  )
}

export default GuideContentAccordion
