import Button from 'components/elements/buttons/button'
import Container from 'components/elements/containers/container'
import BreadCrumbs from 'components/links/breadcrumbs'
import SocialLinks from 'components/links/social-links'
import Markdown from 'components/markdown/markdown'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import { formatCamelCaseOrNumberToLowerWithHyphen } from 'lib/utilities'
import Image from 'next/image'
import { useRouter } from 'next/router'

export type HeroProps = {
  title: string
  intro: string
  image?: string
  imageUrl?: string
  categoryTitle?: string
  buttonText?: string
  buttonLink?: string
  isButtonLinkExternal?: boolean
  phoneNumber?: string
  contactEmail?: string
  followLinks?: boolean
  breadCrumbTitles?: string[]
  backgroundColor?: string
  textColor?: string
  extraLogo?: string
  extraLogoAlt?: string
}

const Hero = ({
  title,
  intro,
  categoryTitle,
  image,
  imageUrl,
  buttonText,
  buttonLink,
  isButtonLinkExternal,
  phoneNumber,
  contactEmail,
  followLinks,
  breadCrumbTitles,
  backgroundColor,
  textColor,
  extraLogo,
  extraLogoAlt,
}: HeroProps) => {
  const router = useRouter()

  return (
    <Container extraClasses="py-0" bgColor={`bg-${formatCamelCaseOrNumberToLowerWithHyphen(backgroundColor)}`}>
      <div
        className={`mx-auto flex max-w-screen-xl flex-col justify-center md:h-[520px] md:w-11/12 md:flex-row text-${formatCamelCaseOrNumberToLowerWithHyphen(
          textColor
        )}`}
      >
        <div className="my-auto flex w-full flex-col items-start gap-4 pt-16 text-left md:w-1/2">
          {breadCrumbTitles && (
            <div className="pt-4 sm:pt-0">
              <BreadCrumbs breadCrumbTitles={breadCrumbTitles} />
            </div>
          )}
          {categoryTitle && <p className="text-xs">{categoryTitle}</p>}
          <span className="text-4xl font-semibold">{title}</span>
          <Markdown>{intro}</Markdown>
          {extraLogo && (
            <div className="h-25 w-25">
              <Image src={extraLogo} alt={extraLogoAlt} width={128} height={128} />
            </div>
          )}
          {(phoneNumber || contactEmail) && (
            <div className="flex space-x-8">
              {phoneNumber && (
                <div className="flex flex-col">
                  <p className="font-semibold opacity-40">Call Us</p>
                  <p className="text-lg">{phoneNumber}</p>
                </div>
              )}
              {contactEmail && (
                <div className="flex flex-col">
                  <p className="font-semibold opacity-40">Email Us</p>
                  <p className="text-lg">{contactEmail}</p>
                </div>
              )}
            </div>
          )}
          {followLinks && (
            <div className="flex flex-col gap-2">
              <p className="font-semibold opacity-40">Follow Us</p>
              <SocialLinks color="text-tella-blue-300" containerClass="h-8" />
            </div>
          )}

          {buttonText && buttonLink && (
            <div className="w-full">
              <Button
                style="Blue"
                width="w-min px-4"
                text={buttonText}
                onClick={() => (isButtonLinkExternal ? window.open(buttonLink, '_blank') : router.push(buttonLink))}
              />
            </div>
          )}
        </div>
        {imageUrl ? (
          <div className="relative mx-auto mt-6 min-h-[18rem] w-full overflow-hidden print:hidden md:mt-[4.5rem] md:w-1/2 [&>span>img]:md:object-right">
            <Image src={imageUrl} alt="Refinance Webinar" layout="fill" objectFit="contain" />
          </div>
        ) : (
          <div className="mx-auto w-full print:hidden sm:w-1/2">{stringToImgComponent(image ?? '')}</div>
        )}
      </div>
    </Container>
  )
}

export default Hero
